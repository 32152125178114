import { createSlice } from '@reduxjs/toolkit'

export const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState: {},
    reducers: {
      saveUserDetailsData: (state, action) => {
        state = action.payload;
        return state;
      },
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { saveUserDetailsData } = userDetailsSlice.actions
  
  export default userDetailsSlice.reducer
import React, { useEffect, useState } from "react";
import './css/login-page.css';
import { event } from "jquery";
import { execute_POST_API } from "../api/api-util";
import { useDispatch, useSelector } from "react-redux";
import { saveUserDetailsData } from "../store/reducers/user-details-slice";
import { saveToken } from "../store/reducers/token-slice";
import { saveMenus } from "../store/reducers/menus-slice";

function LoginPage(props) {
    const dispatch = useDispatch();
    const [errorMsg, setErrorMsg] = useState(props.errorMsg);
    const [msg, setMsg] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [processing, setProcessing] = useState(false);
    const login = (event) => {
        event.preventDefault();
        /*if(email === "" || password === ""){
            setErrorMsg("All fields are mandatory");
            return;
        }*/
        const url = '/login';
        const body = {
            'email': email,
            'password': password
        };
        setProcessing(true);
        execute_POST_API(url, null, body)
            .then(response => response.json())
            .then(result => {
                if (result.iserror) {
                    setErrorMsg(result.errormsg);
                    setProcessing(false);
                } else {
                    if (result.msg) {
                        setMsg(result.msg);
                        setProcessing(false);
                    }
                    if (result.token) {
                        localStorage.setItem("token", result.token);
                        dispatch(saveToken(result.token));
                        dispatch(saveUserDetailsData(result.userprofile));
                        dispatch(saveMenus(result.allowedmenus));
                        props.loginSuccess();
                        setProcessing(false);
                    }
                }
            })
            .catch(error => {
                setErrorMsg("Some Error Occurred");
                console.log(error);
                setProcessing(false);
            });
    }

    useEffect(() => {
        //console.log('In - ' + new Date().toLocaleTimeString());
        if (errorMsg !== "" || msg !== "") {
            setTimeout(() => {
                //console.log('Timout - ' + new Date().toLocaleTimeString());
                setErrorMsg("");
                setMsg("");
            }, 5000);
        }
    }, [errorMsg, msg]);

    return (
        <div>
            <div id="loginbox" className="mx-auto">
                <h4 id="signintext">Sign In</h4>
                <hr style={{ color: 'royalblue' }} />
                <div id="credentialbox">
                    {
                        errorMsg !== "" ?
                            <span id="errorbox">{errorMsg}</span>
                            :
                            msg !== "" ?
                                <span id="msgbox">{msg}</span>
                                :
                                <span>&nbsp;</span>
                    }
                    <form className="form" onSubmit={event => login(event)}>
                        <input type="email" className="form-control textbox" placeholder="Email Address" id="email" value={email} onChange={event => setEmail(event.target.value.trim())} autoFocus />
                        <input type="password" className="form-control mt-2 textbox" placeholder="Password" id="password" value={password} onChange={event => setPassword(event.target.value.trim())} />
                        <button type="submit" className="btn btn-outline-primary mt-5" style={{ width: '-webkit-fill-available' }} disabled={processing}>
                            {
                                !processing ? "Sign In"
                                    :
                                    <div class="spinner-border text-primary" role="status" style={{ width: '1rem', height: '1rem' }}>
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                            }
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default LoginPage;
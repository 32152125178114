import React, { useEffect, useState } from 'react'
import MainComponent from '../MainComponent/main-component';
import LoginPage from './login-page';
import { execute_POST_API } from '../api/api-util';
import { useDispatch } from 'react-redux';
import { saveUserDetailsData } from '../store/reducers/user-details-slice';
import { saveToken } from '../store/reducers/token-slice';
import { saveMenus } from '../store/reducers/menus-slice';

export default function Authentication() {
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLoginPage, setShowLoginPage] = useState(false);
    const [errorMsgForLogin, setErrorMsgForLogin] = useState("");

    const loginSuccess = () => {
        setIsLoggedIn(true);
        setShowLoginPage(false);
    }

    useEffect(() => {
        const token = localStorage.getItem("token");
        if(!token){
            setIsLoggedIn(false);
            setShowLoginPage(true);
        } else {
            execute_POST_API("/authenticate", null, null)
            .then(response => response.json())
            .then(result => {
                //console.log(result);
                if(result.valid){
                    //console.log(result);
                    dispatch(saveToken(result.token));
                    localStorage.setItem("token", result.token);
                    dispatch(saveUserDetailsData(result.userprofile));
                    dispatch(saveMenus(result.allowedmenus));
                    setIsLoggedIn(true);
                    setShowLoginPage(false);
                } else {
                    localStorage.removeItem("token");
                    setIsLoggedIn(false);
                    setShowLoginPage(true);
                    setErrorMsgForLogin(result.msg);
                }
            })
            .catch(error => {
                console.log(error);
                localStorage.removeItem("token");
                setIsLoggedIn(false);
                setShowLoginPage(true);
            })
        }
    }, [])
    return (
        <div>
        {
            isLoggedIn ? 
                <MainComponent/>
            :
                showLoginPage ? 
                    <LoginPage loginSuccess={loginSuccess} errorMsg={errorMsgForLogin}/>
                :
                    <div>
                        Authenticating... Please Wait....
                    </div>
        }
        </div>
  )
}

import { useDispatch } from "react-redux";
import store from '../store/store';
import { saveToken, removeToken } from '../store/reducers/token-slice';

//const base_URL = "http://localhost/v2v_billing_maintenance_new/server/api";
const base_URL = "/api";

export const execute_POST_API = (url, headers, body) => {
    const requestURL = base_URL + url;
    if (headers === null) {
        headers = {};
    }
    if (body === null) {
        body = {};
    }
    headers.Authorization = "Bearer " + (localStorage.getItem("token") ? localStorage.getItem("token") : "");
    return fetch(requestURL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
    });
}

export const execute_GET_API = (url, headers) => {
    const requestURL = base_URL + url;
    if (headers === null) {
        headers = {};
    }
    headers.Authorization = "Bearer " + (localStorage.getItem("token") ? localStorage.getItem("token") : "");
    return fetch(requestURL, {
        method: "GET",
        headers: headers
    });
}

export const responseAuthorisation = (authResponse) => {
    if (authResponse.valid) {
        store.dispatch(saveToken(authResponse.token));
        localStorage.setItem("token", authResponse.token);
        return true;
    }
    alert(authResponse.msg);
    store.dispatch(removeToken());
    localStorage.removeItem("token");
    window.location.reload();
    return false;
}
import { configureStore } from "@reduxjs/toolkit";
import userDetailsReducer from "./reducers/user-details-slice";
import tokenReducer from "./reducers/token-slice";
import menusReducer from "./reducers/menus-slice";

export default configureStore({
    reducer: {
        userDetails : userDetailsReducer,
        token : tokenReducer,
        menus : menusReducer
    },
})
import { createSlice } from '@reduxjs/toolkit'

export const tokenSlice = createSlice({
    name: 'token',
    initialState: "",
    reducers: {
      saveToken: (state, action) => {
        state = action.payload;
        return state;
      },
      removeToken: (state) => {
        return "";
      }
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { saveToken, removeToken } = tokenSlice.actions
  
  export default tokenSlice.reducer
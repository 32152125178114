export const calculateAgeByDOB = (dob) => {
    if(dob.trim() === null || dob.trim() === ""){
        console.log("Date of Birth is Mandatory to calculate Age.");
        return {};
    }
    const dobDateObj = new Date(dob);
    const dobYear = dobDateObj.getFullYear();
    const dobMonth = dobDateObj.getMonth();
    const dobDate = dobDateObj.getDate();

    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentDate = today.getDate();

    if((today.getTime() - dobDateObj.getTime()) < 0){
        console.log("Date of Birth should not be future date");
        return {};
    }
    let yearAge = currentYear - dobYear;
    let monthAge = 0;
    let dateAge = 0;
    
    if(currentMonth >= dobMonth){
        monthAge = currentMonth - dobMonth;
    } else {
        yearAge--;
        monthAge = 12 - (dobMonth - currentMonth);
    }

    if(currentDate >= dobDate){
        dateAge = currentDate - dobDate;
    } else {
        monthAge--;
        const currentMonthTotalDays = new Date(currentYear, (currentMonth + 1), 0).getDate();
        dateAge = currentMonthTotalDays - (dobDate - currentDate);
        if(monthAge < 0){
            monthAge = 11;
            yearAge--;
        }
    }

    const age = {
        years : yearAge,
        months : monthAge,
        days : dateAge
    }

    return age;
}

export const ageInStr = (ageObj) => {
    let ageStr = "";
    if(ageObj.years > 0){
        ageStr = ageObj.years + " Years";
    }
    if(ageObj.months > 0){
        ageStr = ageStr + (ageStr === "" ? "" : " ") + ageObj.months + " Months";
    }
    if(ageObj.days > 0){
        ageStr = ageStr + (ageStr === "" ? "" : " ") + ageObj.days + " Days";
    }
    if(ageStr === ""){
        ageStr = "0 Days";
    }
    return ageStr;
}
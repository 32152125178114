import React from "react";
import './css/main-component.css';
import Header from "../Header/header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "../Home/homepage";
import NoPageFound from "../NoPageFound/nopagefound";
import SaleOrder from "../SaleModule/sale-order";
import LoginPage from "../Authentication/login-page";
import { useSelector } from "react-redux";
import PatientEntry from "../TreatmentModule/patient-entry";
import TreatmentDetails from "../TreatmentModule/treatment-details";
import Users from "../AdminModule/users";

function MainComponent () {
    const pageMapper = {
        "saleorder" : <SaleOrder />,
        "patiententry" : <PatientEntry />,
        "treatmentdetails" : <TreatmentDetails />,
        "users" : <Users />
    };
    const menusarr = useSelector((state) => state.menus);

    return(
        <div id="main-component" className="mx-auto">
            <div>
                <BrowserRouter>
                    <Header/>
                    <div className="row" style={{backgroundColor: '#dcdcdc', minHeight: '900px'}}>
                        <div className="col-12">
                            <Routes>
                                <Route exact path="/" element={<HomePage />} />
                                <Route exact path="" element={<HomePage />} />
                                <Route exact path="home" element={<HomePage />} />
                                <Route exact path="login" element={<HomePage />} />
                                {
                                    menusarr.map((block, index) => {
                                        const menulist = block.menus;
                                        return menulist.map((menu, index) => {
                                            const key = menu.key;
                                            if(pageMapper[key]){
                                                return (<Route exact path={key} element={pageMapper[key]} />)
                                            }
                                        })
                                    })
                                }
                                <Route exact path="*" element={<NoPageFound />} />
                            </Routes>
                        </div>
                        <div className="col-0"/>
                    </div>
                </BrowserRouter>
            </div>
        </div>
    )
}

export default MainComponent;
import React, { useEffect, useState } from "react";
import './css/sale-order.css';
import $, { event } from 'jquery';
import Select from "react-select";
import { FiDelete, FiX, FiEdit } from "react-icons/fi";

function SaleOrder() {

    const [searchType, setSearchType] = useState("prodId");
    const [searchKey, setSearchKey] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [collectedAmount, setCollectedAmount] = useState(0);
    const [returnAmount, setReturnAmount] = useState(0);
    const [printbillcb, setPrintBillCb] = useState(true);
    const [billedItems, setBilledItems] = useState([]);
    const [products, setProducts] = useState([]);
    const [recordNumber, setRecordNumber] = useState("");
    const [recordKey, setRecordKey] = useState("phone");
    const isEnterKeyPressed = (key) => {
        return (key === 'Enter');
    }
    const addItem = (event) => {
        if (isEnterKeyPressed(event.key)) {
            if (searchType === 'prodId') {
                if (searchKey.trim() === "") {
                    alert("Product ID Mandatory");
                } else {
                    const prodIndex = products.findIndex(prod => { return prod.prodId === searchKey });
                    if (prodIndex === -1) {
                        alert("No Product Found");
                    } else {
                        const itemIndex = billedItems.findIndex(item => { return item.prodId === searchKey });
                        addToBilledItems(prodIndex, itemIndex);
                    }
                }
            } else if (searchType === 'prodName') {
                if (searchKey.trim() === "") {
                    alert("Product Name Mandatory");
                } else {
                    const prodIndex = products.findIndex(prod => { return prod.prodName === searchKey });
                    if (prodIndex === -1) {
                        alert("No Product Found");
                    } else {
                        const itemIndex = billedItems.findIndex(item => { return item.prodName === searchKey });
                        addToBilledItems(prodIndex, itemIndex);
                    }
                }
            }
        }
    }
    const addToBilledItems = (prodIndex, itemIndex) => {
        if (itemIndex === -1) {
            const prod = products[prodIndex];
            const currProductName = prod.prodName;
            const currQuantity = 1;
            const currRate = Number(prod.rate);
            const currPrice = currQuantity * currRate;
            let tax = (currPrice * prod.taxPercentage / 100);
            tax = parseFloat(tax).toFixed(2);
            let subtotal = currPrice + Number(tax);
            subtotal = parseFloat(subtotal).toFixed(2);
            const item = {
                prodId: prod.prodId,
                prodName: currProductName,
                rate: prod.rate,
                qty: currQuantity,
                price: currPrice,
                taxPercentage: prod.taxPercentage,
                tax: tax,
                subtotal: subtotal
            };
            let currItems = billedItems;
            currItems.push(item);
            setBilledItems(currItems);
        } else {
            const prod = products[prodIndex];
            let item = billedItems[itemIndex];
            let currQuantity = 1;
            currQuantity = currQuantity + Number(item.qty);
            const currRate = Number(prod.rate);
            const currPrice = currQuantity * currRate;
            let tax = (currPrice * prod.taxPercentage / 100);
            tax = parseFloat(tax).toFixed(2);
            let subtotal = currPrice + Number(tax);
            subtotal = parseFloat(subtotal).toFixed(2);
            item.qty = currQuantity;
            item.price = currPrice;
            item.tax = tax;
            item.subtotal = subtotal;
            let currItems = billedItems;
            currItems[itemIndex] = item;
            setBilledItems(currItems);
        }
        setSearchKey("");
        setSearchType("prodId");
        calculateGrandTotal();
        $("#searchKey").focus();
    }
    const calculateGrandTotal = () => {
        let currGrandTotal = 0;
        let totalQty = 0;
        for (let i = 0; i < billedItems.length; i++) {
            const item = billedItems[i];
            currGrandTotal += Number(item.subtotal);
            totalQty += Number(item.qty);
        }
        const reminder = currGrandTotal % 1;
        if (reminder > 0) {
            currGrandTotal = (currGrandTotal - reminder + 1);
        }
        setGrandTotal(currGrandTotal);
        setTotalItems(billedItems.length);
        setTotalQuantity(totalQty);
    }
    const searchInputSwitch = (event) => {
        setSearchType(event.target.value);
        setSearchKey("");
        if (event.target.value === 'prodId') {
            $("#searchKey").attr("placeholder", "Enter Product ID");
        } else {
            $("#searchKey").attr("placeholder", "Type 3 letters to search");
        }
    }
    const recordKeySwitch = (event) => {
        setRecordKey(event.target.value);
        setRecordNumber("");
        if (event.target.value === 'phone') {
            $("#recordnumber").attr("placeholder", "Enter Phone Number");
        } else if (event.target.value === 'token') {
            $("#recordnumber").attr("placeholder", "Enter Token Number");
        } else {
            $("#recordnumber").attr("placeholder", "Enter MR Number");
        }
    }
    const changeQtyInBilledItems = (index, qty) => {
        let item = billedItems[index];
        let currQuantity = qty;
        const currRate = Number(item.rate);
        const currPrice = currQuantity * currRate;
        let tax = (currPrice * item.taxPercentage / 100);
        tax = parseFloat(tax).toFixed(2);
        let subtotal = currPrice + Number(tax);
        subtotal = parseFloat(subtotal).toFixed(2);
        item.qty = currQuantity;
        item.price = currPrice;
        item.tax = tax;
        item.subtotal = subtotal;
        let currItems = billedItems;
        currItems[index] = item;
        setBilledItems(currItems);
        calculateGrandTotal();
    }
    const amountCollected = (event) => {
        const collectedAmount = event.target.value
        setCollectedAmount(collectedAmount);
        setReturnAmount(collectedAmount - grandTotal)
    }
    const removeBilledItem = (index) => {
        let items =  billedItems;
        items.splice(index, 1);
        setBilledItems(items);
        calculateGrandTotal();
    }
    useEffect(() => {
        setProducts([
            {
                prodId: "1",
                prodName: "Paracetamol 350mg",
                rate: 4,
                taxPercentage: 18
            },
            {
                prodId: "2",
                prodName: "Paracetamol 650mg",
                rate: 6,
                taxPercentage: 18
            },
            {
                prodId: "3",
                prodName: "Albendazole Tablet",
                rate: 10,
                taxPercentage: 4
            },
            {
                prodId: "4",
                prodName: "Albendazole Syrup",
                rate: 25,
                taxPercentage: 4
            }
        ]);
        //$("#billbottom").css("display", (billedItems.length === 0 ? "none" : "block"));
    }, []);
    return (
        <div id="sale-order-container" className='mx-auto'>
            <br />
            <div className="row">
                <div className="col-12">
                    <nav id="breadcrumbnav" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Pharmacy</li>
                            <li className="breadcrumb-item active" aria-current="page">Sale Order</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row" style={{ border: '3px ridge', borderBottom: 'none', borderRadius: '12px' }}>
                <div className="col-lg-12 col-12 ps-4 text-center">
                    <div className="row pb-2 pt-1" style={{ borderBottom: '3px ridge' }}>
                        <div className="col-4">
                            <div className='input-group'>
                                <select className='form-select' value={recordKey} onChange={event => recordKeySwitch(event)}>
                                    <option value={"phone"}>Phone Number</option>
                                    <option value={"token"}>Token Number</option>
                                    <option value={"mr"}>MR Number</option>
                                </select>
                                <input type="text" id="recordnumber" placeholder="Enter Phone Number" className="form-control" value={recordNumber} onChange={event => setRecordNumber(event.target.value)}/>
                            </div>
                        </div>
                        <div className="col-6 offset-2 text-start">
                            <div>
                                <h5><b><u>Patient Details:</u></b></h5>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Name</th>
                                            <th> : </th>
                                            <td>Mohan E</td>
                                            <td>&nbsp;&nbsp;&nbsp;</td>
                                            <th>Blood Group</th>
                                            <th> : </th>
                                            <td>B+</td>
                                        </tr>
                                        <tr>
                                            <th>Age</th>
                                            <th> : </th>
                                            <td>32</td>
                                            <td>&nbsp;&nbsp;&nbsp;</td>
                                            <th>Phone Number</th>
                                            <th> : </th>
                                            <td>7299008115</td>
                                        </tr>
                                        <tr>
                                            <th>DOB</th>
                                            <th> : </th>
                                            <td>06-05-1991</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row pb-3">
                        <div className='col-12 order-2 order-lg-1 col-lg-7 pt-3'>
                            <div className='input-group'>
                                <select className='form-select' onChange={(event) => searchInputSwitch(event)} value={searchType}>
                                    <option value={"prodId"}>Prod ID</option>
                                    <option value={"prodName"}>Prod Name</option>
                                </select>
                                <input type='text' id='searchKey' placeholder='Enter Product ID' className='form-control' style={{ width: '55%' }} list="datalistOptions" value={searchKey} onKeyDown={(event) => addItem(event)} onChange={(event) => setSearchKey(event.target.value)} autoFocus/>
                                <datalist id="datalistOptions">
                                    {searchType === "prodName" && products.map((product, index) => {
                                        return <option value={product.prodName} />
                                    })}
                                </datalist>
                            </div>
                        </div>
                        <div className='col-lg-5 order-1 order-lg-2' id="overalldatadiv">
                            <div className='row'>
                                <div className="col-4 text-center">
                                    <label className="form-label" >Total Items</label>
                                    <div>{totalItems}</div>
                                </div>
                                <div className="col-4 text-center">
                                    <label className="form-label" >Total Quantity</label>
                                    <div>{totalQuantity}</div>
                                </div>
                                <div className="col-4 text-center">
                                    <label className="form-label fw-bolder" >Grand Total</label>
                                    <div style={{ color: 'red', fontSize: 'x-large', fontWeight: 'bold' }}>&#8377;{grandTotal}</div>
                                </div>
                            </div>
                        </div>

                        {/*<div className="col-lg-2">
                            <label className="form-label">Product ID</label>
                            <input type="text" className="form-control" value={prodId} onChange={(event) => prodIdSelected(event)} />
                        </div>
                        <div className="col-lg-4">
                            <label className="form-label">Product Name</label>
                            <Select options={productNameList} placeholder={<div>Type 3 chars to search</div>} value={prodName} onChange={(selectedOption) => setProdName(selectedOption)}>
                            </Select>
                        </div>
                        <div className="col-lg-1">
                            <label className="form-label">&nbsp;</label><br />
                            <input type="button" className="btn btn-primary" value={"Add"} style={{ width: '-webkit-fill-available' }} />
                        </div>*/}
                    </div>
                    <div className="row">
                        <div className="col-12" style={{ overflow: 'scroll' }}>
                            <table className="table table-striped table-primary table-hover table-responsive border" style={{ overflow: 'scroll' }}>
                                <thead style={{ verticalAlign: 'top' }}>
                                    <tr>
                                        <th></th>
                                        <th>Product ID</th>
                                        <th>Product Name</th>
                                        <th>Quantity</th>
                                        <th className="text-end">Unit Rate</th>
                                        <th className="text-end">Price</th>
                                        <th className="text-end">Tax</th>
                                        <th className="text-end">Sub Total</th>
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                    {billedItems.map((item, index) => {
                                        return (
                                            <tr>
                                                <td><FiX style={{ color: 'red', cursor: 'pointer'}} onClick={() => removeBilledItem(index)}/></td>
                                                <td>{item.prodId}</td>
                                                <td>{item.prodName}</td>
                                                <td align="center"><input type="number" className="form-control text-center" value={item.qty} onChange={event => changeQtyInBilledItems(index, event.target.value)} style={{ width: '55px' }} /></td>
                                                <td className="text-end">&#8377;{item.rate}</td>
                                                <td className="text-end">&#8377;{item.price}</td>
                                                <td className="text-end">&#8377;{item.tax}<br /><span className="fst-italic fw-light">{item.taxPercentage}%</span></td>
                                                <td className="text-end">&#8377;{item.subtotal}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ border: '3px ridge', borderRadius: '12px'}} id="billbottom">
                <div className="col-lg-2 offset-lg-10 col-5 offset-7 text-end"><br />
                    <div className="row">
                        <div className="col-12">
                            <label className="form-label fw-bold">Collected Amount</label>
                            <input type="number" className="form-control" value={collectedAmount} onChange={(event) => amountCollected(event)} disabled={billedItems.length === 0}/>
                        </div>
                    </div><br />
                    <div className="row">
                        <div className="col-12">
                            <label className="form-label fw-bold">Return Amount</label>
                            <div style={{ color: 'green', fontSize: 'x-large', fontWeight: 'bold' }}>&#8377;{returnAmount}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-start"><br/>
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="printbillcb" checked={printbillcb} onClick={(event => setPrintBillCb(event.target.checked)) } disabled={billedItems.length === 0}/>
                                <label htmlFor="printbillcb" className="form-check-label" >&nbsp;Print Bill</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <input type="button" className={"btn " + (printbillcb ? "btn-primary" : "btn-outline-primary")} value={"Save " + (printbillcb ? "& Print " : "") + "Bill"} style={{width : '-webkit-fill-available'}} disabled={billedItems.length === 0}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SaleOrder;
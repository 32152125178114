import { createSlice } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
    name: 'menus',
    initialState: [],
    reducers: {
      saveMenus: (state, action) => {
        state = action.payload;
        return state;
      }
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { saveMenus } = menuSlice.actions
  
  export default menuSlice.reducer
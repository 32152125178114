import React, { useEffect, useState } from 'react';
import { execute_POST_API, execute_GET_API, responseAuthorisation } from '../api/api-util';
import { FiEdit3, FiEye, FiEyeOff } from 'react-icons/fi';
import './css/users.css';
import { error, event } from 'jquery';

export default function Users() {
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [branches, setBranches] = useState([]);
    const fieldMaxLen = {
        "fname": 30,
        "lname": 20,
        "email": 50,
        "phone": 10,
        "password": 15
    }
    const [totalUsers, setTotalUsers] = useState(0);
    const [countPerPage, setCountPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [newUserPasswordVisible, setNewUserPasswordVisible] = useState(false);
    const [inputErrorMsg, setInputErrorMsg] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [role, setRole] = useState(0);
    const [branch, setBranch] = useState(0);
    const [password, setPassword] = useState("");
    const [usersAddedCount, setUsersAddedCount] = useState(0);

    const setInput = (field, value, trim) => {
        let limitedValue = value;
        if (fieldMaxLen[field]) {
            limitedValue = value.substring(0, fieldMaxLen[field]);
        }
        if (trim) {
            limitedValue = limitedValue.trim();
        }
        switch (field) {
            case 'fname':
                setFirstName(limitedValue);
                break;
            case 'lname':
                setLastName(limitedValue);
                break;
            case 'email':
                setEmail(limitedValue);
                break;
            case 'phone':
                setPhoneNumber(limitedValue);
                break;
            case 'role':
                setRole(limitedValue);
                break;
            case 'password':
                setPassword(limitedValue);
                break;
        }
    }

    const resetNewUserForm = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        if (roles.length == 1) {
            setRole(roles[0].id);
        } else {
            setRole(0);
        }
        if (branches.length == 1) {
            setBranch(branches[0].id);
        } else {
            setBranch(0);
        }
        setPassword("");
    }

    const addNewUser = () => {
        if (firstName === "") {
            setInputErrorMsg("First Name Mandatory");
            return;
        }
        if (lastName === "") {
            setInputErrorMsg("Last Name Mandatory");
            return;
        }
        if (email === "") {
            setInputErrorMsg("Email Mandatory");
            return;
        }
        if (phoneNumber === "") {
            setInputErrorMsg("Phone Number Mandatory");
            return;
        }
        if (phoneNumber.length < 10) {
            setInputErrorMsg("Phone Number Should Be 10 Digit");
            return;
        }
        if (role === 0) {
            setInputErrorMsg("Role Mandatory");
            return;
        }
        if (branch == "") {
            setInputErrorMsg("Branch Mandatory");
            return;
        }
        if (password === "") {
            setInputErrorMsg("Password Mandatory");
            return;
        }
        const url = "/users";
        const body = {
            'fname': firstName,
            'lname': lastName,
            email,
            role,
            branch,
            'phone': phoneNumber,
            password
        }
        execute_POST_API(url, null, body)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        setInputErrorMsg(result.errormsg);
                    } else {
                        alert(result.data);
                        resetNewUserForm();
                        setUsersAddedCount(usersAddedCount + 1);
                    }
                }
            });
    }

    useEffect(() => {
        const startingRecord = ((Number(countPerPage) * (Number(currentPage) - 1)) + 1);
        const url = "/users?from=" + startingRecord + "&limit=" + Number(countPerPage);
        execute_GET_API(url, null)
            .then(response => response.json())
            .then(result => {
                //console.log(result);
                if (responseAuthorisation(result)) {
                    //if (result.valid) {
                    if (result.iserror) {
                        alert(result.errormsg);
                    } else {
                        setUsers(result.data);
                    }
                } else {
                    //localStorage.removeItem("token");
                }
            })
            .catch(error => {
                console.log(error);
            })
    }, [currentPage, countPerPage, usersAddedCount]);

    useEffect(() => {
        if (inputErrorMsg !== "") {
            setTimeout(() => {
                setInputErrorMsg("");
            }, 5000);
        }
    }, [inputErrorMsg]);

    useEffect(() => {
        execute_GET_API("/branches", null)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        alert(result.errormsg);
                    } else {
                        setBranches(result.data);
                        if (result.data.length == 1) {
                            setBranch(result.data[0].id);
                        }
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        execute_GET_API("/user-roles", null)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        alert(result.errormsg);
                    } else {
                        setRoles(result.data);
                        if (result.data.length == 1) {
                            setRole(result.data[0].id);
                        }
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <div className='view-area pt-3' style={{fontSize: '0.8rem'}}>
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newUserPopUp">
                Add New User
            </button>
            <div className="modal fade" id="newUserPopUp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="newUserPopUpLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="newUserPopUpLabel">Add New User</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{ fontSize: '1rem' }}>
                            <div className='row'>
                                <div className='col-12 text-center text-danger'>
                                    {inputErrorMsg === "" ? <span>&nbsp;</span> : inputErrorMsg}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <label className='form-label'><span className='text-danger'>* </span>First Name <span className='char-count'>{firstName.length + "/" + fieldMaxLen['fname']}</span></label>
                                    <input type='text' className='form-control' placeholder='First Name' value={firstName} onChange={event => setInput('fname', event.target.value, true)} />
                                </div>
                                <div className='col-6'>
                                    <label className='form-label'><span className='text-danger'>* </span>Last Name <span className='char-count'>{lastName.length + "/" + fieldMaxLen['lname']}</span></label>
                                    <input type='text' className='form-control' placeholder='Last Name' value={lastName} onChange={event => setInput('lname', event.target.value, true)} />
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-6'>
                                    <label className='form-label'><span className='text-danger'>* </span>Email <span className='char-count'>{email.length + "/" + fieldMaxLen['email']}</span></label>
                                    <input type='email' className='form-control' placeholder='Email' value={email} onChange={event => setInput('email', event.target.value, true)} />
                                </div>
                                <div className='col-6'>
                                    <label className='form-label'>
                                        <span className='text-danger'>* </span>Password <span className='char-count'>{password.length + "/" + fieldMaxLen['password']}</span> &nbsp;
                                        <span style={{ cursor: 'pointer' }} onClick={() => setNewUserPasswordVisible(!newUserPasswordVisible)}>
                                            {newUserPasswordVisible ? <FiEye /> : <FiEyeOff />}
                                        </span>&nbsp;
                                    </label>
                                    {newUserPasswordVisible ?
                                        <input type='text' className='form-control' placeholder='Password' value={password} onChange={event => setInput('password', event.target.value, true)} />
                                        :
                                        <input type='password' className='form-control' placeholder='Password' value={password} onChange={event => setInput('password', event.target.value, true)} />
                                    }
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-6'>
                                    <label className='form-label'><span className='text-danger'>* </span>Role</label>
                                    <select className='form-select' value={role} onChange={event => setInput('role', event.target.value, true)} disabled={roles.length < 2}>
                                        <option value={0}>Select Role</option>
                                        {
                                            roles.map((roleObj, index) => {
                                                return <option value={roleObj.id}>{roleObj.role_name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className='col-6'>
                                    <label className='form-label'><span className='text-danger'>* </span>Branch</label>
                                    <select className='form-select' value={branch} onChange={event => setInput('branch', event.target.value, true)} disabled={branches.length < 2}>
                                        <option value={0}>Select Branch</option>
                                        {
                                            branches.map((branchObj, index) => {
                                                return <option value={branchObj.id}>{branchObj.branch_name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-6'>
                                    <label className='form-label'><span className='text-danger'>* </span>Phone Number <span className='char-count'>{phoneNumber.length + "/" + fieldMaxLen['phone']}</span></label>
                                    <input type='number' className='form-control' placeholder='Phone Number' value={phoneNumber} onChange={event => setInput('phone', event.target.value, true)} style={{ textAlign: 'start' }} />
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-8 mx-auto'>
                                    <button type='button' className='btn btn-primary fullsizedbutton' onClick={addNewUser}>Add</button>
                                </div>
                                <div className='col-4 mx-auto'>
                                    <button type='button' className='btn btn-secondary fullsizedbutton' onClick={resetNewUserForm}>Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-2'>
                    <div className='row'>
                        <div className='col-7'><label className='form-label'>Records Per Page :</label></div>
                        <div className='col-4'>
                            <select className='form-select-xs' value={countPerPage} onChange={event => setCountPerPage(event.target.value)} >
                                <option value={2}>2</option>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <table className='table table-sm table-hover table-striped table-primary table-responsive'>
                <thead>
                    <tr>
                        <th></th>
                        <th>#</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Role</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => {
                        return (
                            <tr>
                                <td>
                                    <div className="dropdown">
                                        <span className="" data-bs-toggle="dropdown" aria-expanded="false" style={{ cursor: 'pointer' }}>
                                            <FiEdit3 />
                                        </span>
                                        <ul className="dropdown-menu" style={{ cursor: 'pointer' }}>
                                            <li><span className="dropdown-item">Reset Password</span></li>
                                            <li><span className="dropdown-item">Edit</span></li>
                                            <li><span className="dropdown-item">Deactivate</span></li>
                                        </ul>
                                    </div>
                                </td>
                                <td>{((Number(currentPage) - 1) * Number(countPerPage)) + index + 1}</td>
                                <td>{user.fname}</td>
                                <td>{user.lname}</td>
                                <td>{user.email}</td>
                                <td>{user.phone}</td>
                                <td>{user.role_name}</td>
                            </tr>)
                    })}
                </tbody>
            </table>
        </div>
    )
}

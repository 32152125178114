import React, { useEffect, useState } from "react";
import './css/header.css';
import { FiMenu, FiX } from 'react-icons/fi';
import { Link, useLocation } from "react-router-dom";
import $ from 'jquery';
import { useDispatch, useSelector } from "react-redux";
import { removeToken } from "../store/reducers/token-slice";

function Header() {
    const userDetails = useSelector((state) => state.userDetails);
    const menusarr = useSelector((state) => state.menus);
    const dispatch = useDispatch();
    
    /*const menusl = [
        {
            url: 'saleorder',
            title: 'Sale Order'
        },
        {
            url: 'salereport',
            title: 'Sale Report'
        }
    ];*/
    const [currPage, setCurrPage] = useState("Home");
    const [activeBlock, setActiveBlock] = useState("");
    const location = useLocation();
    const { hash, pathname, search } = location;
    /*menusarr.forEach(block => {
        const submenuarr = block.menus;
        submenuarr.forEach(menu => {
            if(menu.key === pathname){
                activeBlock = block;
            }
        });
    });*/
    const closeNavBar = (title) => {
        $("#navigation-bar-close-bt").trigger("click");
        setCurrPage(title);
    };
    const logout = () => {
        localStorage.removeItem("token");
        dispatch(removeToken());
        window.location.reload();
    }
    /*useEffect(() => {
        const index = menus.findIndex(menu => "/" + menu.key === pathname);
        if (index === -1) {
            setCurrPage("Home");
        } else {
            const menu = menus[index];
            setCurrPage(menu.title);
        }
    }, []);*/
    useEffect(() => {
        for(let blockloop=0; blockloop < menusarr.length; blockloop++){
            const block = menusarr[blockloop];
            const submenuarr = block.menus;
            for(let menuloop=0; menuloop < submenuarr.length; menuloop++){
                const menu = submenuarr[menuloop];
                if("/" + menu.key === pathname){
                    setActiveBlock(block.key);
                    setCurrPage(menu.title);
                    break;
                }
            }
        };
    }, [])
    return (
        <div id="header-component">
            <div className="row">
                <div id="menu-icon-div" className="col-2">
                    <button className="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#navigation" aria-controls="navigation">
                        <FiMenu id="menu-icon" />
                    </button>
                    <div className="offcanvas offcanvas-start" style={{ backgroundColor: '#dcdcdc' }} tabIndex="-1" id="navigation" aria-labelledby="navigationLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="navigationLabel">{currPage}</h5>
                            <button type="button" id="navigation-bar-close-bt" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <div className="accordion" id="navigation-accordion" style={{ backgroundColor: '#dcdcdc' }}>
                                {
                                    menusarr.map((block, index) => {
                                        const menulist = block.menus;
                                        return (<div className="accordion-item">
                                            <h2 className="accordion-header" id={"heading" + index}>
                                                <button className={"accordion-button" + (activeBlock === block.key ? "" : " collapsed")} type="button" data-bs-toggle="collapse" data-bs-target={"#" + block.key} aria-expanded="true" aria-controls={block.key}>
                                                    {block.title}
                                                </button>
                                            </h2>
                                            <div id={block.key} className={"accordion-collapse collapse" + (activeBlock === block.key ? " show" : "")} aria-labelledby={"heading" + index} data-bs-parent="#navigation-accordion">
                                                <div className="accordion-body">
                                                    <ul className="list-group list-group-flush">
                                                        {menulist.map((menu, index) => {
                                                            return (
                                                                <Link to={menu.key} className={"list-group-item" + ("/" + menu.key === pathname ? " active" : "")} onClick={() => closeNavBar(menu.title)} key={menu.key}>{menu.title}</Link>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>)
                                    })
                                }
                                {/*<div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#pharmacy" aria-expanded="true" aria-controls="pharmacy">
                                            Pharmacy
                                        </button>
                                    </h2>
                                    <div id="pharmacy" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#navigation-accordion">
                                        <div className="accordion-body">
                                            <ul className="list-group list-group-flush">
                                                {menus.map((menu, index) => {
                                                    return (
                                                        <Link to={menu.key} className={"list-group-item" + ("/" + menu.key === pathname ? " active" : "")} onClick={() => closeNavBar(menu.title)} key={menu.key}>{menu.title}</Link>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Treatment
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#navigation-accordion">
                                        <div className="accordion-body">

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Maintenance
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#navigation-accordion">
                                        <div className="accordion-body">

                                        </div>
                                    </div>
                                </div>
                                {
                                    userDetails.role === "Super Admin" ?
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    Admin Console
                                                </button>
                                            </h2>
                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#navigation-accordion">
                                                <div className="accordion-body">

                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-8">
                    {/*<Link to={"home"} style={{ textDecoration: 'none' }} onClick={() => setCurrPage("Home")}>
                        <div className="row">
                            <div className="col-12 col-lg-12">
                                <div id="header-brand">
                                    V2V Clinic
                                </div>
                            </div>
                            <div className="col-12 col-lg-12">
                                <div id="header-brand-sub" className="">
                                    Billing and Maintenance
                                </div>
                            </div>
                        </div>
                            </Link>*/}
                </div>
                <div id="user-icon-div" className="col-2">

                    <button className="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#userdetails" aria-controls="userdetails">
                        <img src='images/user_icon.png' id="user-icon" />
                    </button>

                    <div className="offcanvas offcanvas-end" style={{ backgroundColor: '#dcdcdc', width: '20%' }} tabIndex="-1" id="userdetails" aria-labelledby="userdetailsLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="userdetailsLabel">Profile</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body text-center">
                            <div>
                                <div>{userDetails.fname}&nbsp;{userDetails.lname}</div>
                                <div>Role : {userDetails.role_name}</div>
                                <div>Branch : {userDetails.branch_name}</div>
                                <button className="btn btn-outline-secondary" onClick={logout} style={{ width: '-webkit-fill-available', margin: '10%' }}>Sign Out</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { FiDelete, FiX, FiEdit, FiEdit2, FiEdit3 } from "react-icons/fi";
import './css/patient-entry.css';
import { execute_GET_API, execute_POST_API, responseAuthorisation } from '../api/api-util';
import { ageInStr, calculateAgeByDOB } from '../CommonUtil/common-util';

export default function PatientEntry() {
    const userDetails = useSelector((state) => state.userDetails);

    // Patient Entry Page
    const [waitingPatients, setWaitingPatients] = useState([]);
    const [activeTab, setActiveTab] = useState("waiting");
    const [newPatientEntryAddedCount, setNewPatientEntryAddedCount] = useState(0);
    const [refreshPatientEntryCount, setRefreshPatientEntryCount] = useState(0);
    const [patientEntryListLoading, setPatientEntryListLoading] = useState(false);
    const [patientEntryTotalRecords, setPatientEntryTotalRecords] = useState(0);
    const [patientEntryCurrentPage, setPatientEntryCurrentPage] = useState(1);
    const [patientEntryRecPerPage, setPatientEntryRecPerPage] = useState(10);
    const [patientEntryTotalPages, setPatientEntryTotalPages] = useState(1);
    const [patientEntryPageNosToShow, setPatientEntryPageNosToShow] = useState([]);
    const patientEntryPageNosCount = 5;

    useEffect(() => {
        setWaitingPatients([]);
        const branch = userDetails.bid;
        const from = ((patientEntryCurrentPage - 1) * patientEntryRecPerPage);
        const limit = patientEntryRecPerPage;
        const url = "/treatment/patient-entry?branch=" + branch + "&tab=" + activeTab + "&from=" + from + "&limit=" + limit;
        setPatientEntryListLoading(true);
        execute_GET_API(url, null)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        alert(result.errormsg);
                        setWaitingPatients([]);
                    } else {
                        setPatientEntryTotalRecords(result.data.count);
                        setWaitingPatients(result.data.records);
                    }
                }
                setPatientEntryListLoading(false);
            })
            .catch(error => {
                console.log(error);
                alert("Some Error Occurred");
                setPatientEntryListLoading(false);
                setWaitingPatients([]);
            });
    }, [activeTab, newPatientEntryAddedCount, refreshPatientEntryCount, patientEntryRecPerPage, patientEntryCurrentPage]);

    useEffect(() => {
        var totalPages = Math.floor(patientEntryTotalRecords / patientEntryRecPerPage);
        const remainder = patientEntryTotalRecords % patientEntryRecPerPage;
        if (remainder > 0) {
            totalPages += 1;
        }
        if (totalPages === 0) {
            totalPages = 1;
        }
        if (patientEntryCurrentPage > totalPages) {
            setPatientEntryCurrentPage(1);
        }
        setPatientEntryTotalPages(totalPages);
        var pageStartNumber = patientEntryCurrentPage - 2;
        if((totalPages - patientEntryCurrentPage) < 2){
            pageStartNumber = pageStartNumber - (2 - (totalPages - patientEntryCurrentPage));
        }
        if(pageStartNumber < 1){
            pageStartNumber = 1;
        }
        var pageEndNumber = patientEntryCurrentPage + 2;
        if(patientEntryCurrentPage < 3){
            pageEndNumber = pageEndNumber + (3 - patientEntryCurrentPage);
        }
        if(pageEndNumber > totalPages){
            pageEndNumber = totalPages;
        }
        /*if (patientEntryCurrentPage > patientEntryPageNosCount) {
            pageStartNumber = (patientEntryCurrentPage - patientEntryPageNosCount + 1);
        }
        var pageEndNumber = pageStartNumber + patientEntryPageNosCount - 1;
        if (pageEndNumber > totalPages) {
            pageEndNumber = totalPages;
        }*/
        var pagesToShow = [];
        for (var index = pageStartNumber; index <= pageEndNumber; index++) {
            pagesToShow.push(index);
        }
        setPatientEntryPageNosToShow(pagesToShow);
    }, [patientEntryCurrentPage, patientEntryRecPerPage, patientEntryTotalRecords]);

    //Add New Entry Form
    const [tokenNumber, setTokenNumber] = useState(0);
    const [isGeneratingToken, setIsGeneratingToken] = useState(false);
    const [nextPatient, setNextPatient] = useState({});
    const [phoneNumberToSearch, setPhoneNumberToSearch] = useState("");
    const [patientSearchResults, setPatientSearchResults] = useState([]);
    const [patientWeight, setPatientWeight] = useState("");
    const [patientAge, setPatientAge] = useState("");
    const [patientBP, setPatientBP] = useState("");
    const [patientTemperature, setPatientTemperature] = useState("");
    const [patientSugar, setPatientSugar] = useState("");
    const [patientSymptoms, setPatientSymptoms] = useState("");
    const [patientProbBrief, setPatientProbBrief] = useState("");
    const [patientAttendantName, setPatientAttendantName] = useState("");
    const [patientAttendantPhNumber, setPatientAttendantPhNumber] = useState("");
    const [searchingPatientDetails, setSearchingPatientDetails] = useState(false);
    const [newEntryErrorMsg, setNewEntryErrorMsg] = useState("");

    const generateToken = (event) => {
        event.preventDefault();
        const id = nextPatient.id;
        if (patientWeight.trim() === "") {
            setNewEntryErrorMsg("Weight is Mandatory");
            return;
        }
        if (patientAge.trim() === "") {
            setNewEntryErrorMsg("Age is Mandatory");
            return;
        }
        if (patientSymptoms.trim() === "") {
            setNewEntryErrorMsg("Symptoms is Mandatory");
            return;
        }
        const body = {
            uid: userDetails.id,
            id: nextPatient.id,
            weight: patientWeight,
            age: patientAge,
            temperature: patientTemperature,
            bp: patientBP,
            sugar: patientSugar,
            symptoms: patientSymptoms,
            brief: patientProbBrief,
            attendantname: patientAttendantName,
            attendantphone: patientAttendantPhNumber,
            branch: userDetails.bid,
            type: "Offline"
        }
        setIsGeneratingToken(true);
        execute_POST_API("/treatment/patient-entry", null, body)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        setNewEntryErrorMsg(result.errormsg);
                    } else {
                        setTokenNumber(result.data);
                        setNewPatientEntryAddedCount(newPatientEntryAddedCount + 1);
                    }
                    setIsGeneratingToken(false);
                }
            })
            .catch(error => {
                console.log(error);
                setNewEntryErrorMsg("Some Error Occurrd");
                setIsGeneratingToken(false);
            });
    }

    const searchPatient = (event) => {
        event.preventDefault();
        if (phoneNumberToSearch.trim() === "") {
            alert("Phone Number is Mandatory");
            return;
        }
        if (phoneNumberToSearch.length < 10) {
            alert("Phone Number should be 10 digit");
            return;
        }
        setSearchingPatientDetails(true);
        const url = "/treatment/patient?phone=" + encodeURI(phoneNumberToSearch);
        execute_GET_API(url, null)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        alert(result.errormsg);
                    } else {
                        setPatientSearchResults(result.data);
                    }
                    setSearchingPatientDetails(false);
                }
            })
            .catch(error => {
                console.log(error);
                setSearchingPatientDetails(false);
            });
    }

    const changeNextPatient = () => {
        setNextPatient({});
        resetPatientEntryForm();
    }

    const setNextPatientDetail = (patient) => {
        resetPatientSearchResults();
        resetPatientEntryForm();
        setNextPatient(patient);
        if (patient.dob !== null) {
            const ageObj = calculateAgeByDOB(patient.dob);
            setPatientAge(ageInStr(ageObj));
        }
    }

    const resetPatientSearchResults = () => {
        setNextPatient({});
        setPhoneNumberToSearch("");
        setPatientSearchResults([]);
    }

    const resetPatientEntryForm = () => {
        setPatientWeight("");
        setPatientAge("");
        setPatientTemperature("");
        setPatientBP("");
        setPatientSugar("");
        setPatientSymptoms("");
        setPatientProbBrief("");
        setPatientAttendantName("");
        setPatientAttendantPhNumber("");
    }

    const resetTokenNumber = () => {
        resetPatientEntryForm();
        resetPatientSearchResults();
        setIsGeneratingToken(false);
        setTokenNumber(0);
    }

    const fieldMaxLen = {
        newPatientFName: 30,
        newPatientLName: 20,
        newPatientPhone: 10,
        newPatientBloodGroup: 10,
        patientSymptoms: 200,
        patientProbBrief: 200,
        patientWeight: 5,
        patientAge: 30,
        patientBP: 10,
        patientTemperature: 10,
        patientSugar: 10,
        patientAttendantName: 50,
        patientAttendantPhNumber: 10
    }

    const limitInputData = (field, value, trim) => {
        let limitedValue = value;
        if (fieldMaxLen[field]) {
            limitedValue = value.substring(0, fieldMaxLen[field]);
        }
        if (trim) {
            limitedValue = limitedValue.trim();
        }
        return limitedValue;
    }

    const setNewEntryInput = (field, value, trim) => {
        const limitedValue = limitInputData(field, value, trim);
        switch (field) {
            case 'patientWeight':
                setPatientWeight(limitedValue);
                break;
            case 'patientAge':
                setPatientAge(limitedValue);
                break;
            case 'patientBP':
                setPatientBP(limitedValue);
                break;
            case 'patientTemperature':
                setPatientTemperature(limitedValue);
                break;
            case 'patientSugar':
                setPatientSugar(limitedValue);
                break;
            case 'patientSymptoms':
                setPatientSymptoms(limitedValue);
                break;
            case 'patientProbBrief':
                setPatientProbBrief(limitedValue);
                break;
            case 'patientAttendantName':
                setPatientAttendantName(limitedValue);
                break;
            case 'patientAttendantPhNumber':
                setPatientAttendantPhNumber(limitedValue);
                break;
        }
    }

    useEffect(() => {
        if (newEntryErrorMsg !== "") {
            setTimeout(() => {
                setNewEntryErrorMsg("");
            }, 5000);
        }
    }, [newEntryErrorMsg]);

    // Add New Patient Popup
    const [addNewPatientErrorMsg, setAddNewPatientErrorMsg] = useState("");
    const [newPatientFName, setNewPatientFName] = useState("");
    const [newPatientLName, setNewPatientLName] = useState("");
    const [newPatientPhone, setNewPatientPhone] = useState("");
    const [newPatientDOB, setNewPatientDOB] = useState("");
    const [newPatientBloodGroup, setNewPatientBloodGroup] = useState("");

    const setNewPatientInput = (field, value, trim) => {
        const limitedValue = limitInputData(field, value, trim);
        switch (field) {
            case 'newPatientFName':
                setNewPatientFName(limitedValue);
                break;
            case 'newPatientLName':
                setNewPatientLName(limitedValue);
                break;
            case 'newPatientPhone':
                setNewPatientPhone(limitedValue);
                break;
            case 'newPatientDOB':
                setNewPatientDOB(limitedValue);
                break;
            case 'newPatientBloodGroup':
                setNewPatientBloodGroup(limitedValue);
                break;
        }
    }
    const addNewPatient = () => {
        if (newPatientFName === "") {
            setAddNewPatientErrorMsg("First Name is Mandatory");
            return;
        }
        if (newPatientLName === "") {
            setAddNewPatientErrorMsg("Last Name is Mandatory");
            return;
        }
        if (newPatientPhone === "") {
            setAddNewPatientErrorMsg("Phone Number is Mandatory");
            return;
        }
        const body = {
            fname: newPatientFName,
            lname: newPatientLName,
            phone: newPatientPhone,
            dob: newPatientDOB,
            bloodgroup: newPatientBloodGroup
        };
        execute_POST_API("/treatment/patient", null, body)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        setAddNewPatientErrorMsg(result.errormsg);
                    } else {
                        alert(result.data);
                        resetAddNewPatientForm();
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    const resetAddNewPatientForm = () => {
        setNewPatientFName("");
        setNewPatientLName("");
        setNewPatientPhone("");
        setNewPatientDOB("");
        setNewPatientBloodGroup("");
    }
    useEffect(() => {
        if (addNewPatientErrorMsg !== "") {
            setTimeout(() => {
                setAddNewPatientErrorMsg("");
            }, 5000);
        }
    }, [addNewPatientErrorMsg]);

    return (
        <div className='mt-5 view-area'>
            <div className='row'>
                <div className='col-12 mx-auto'>
                    <div className='row'>
                        <div className='col-6'>
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newEntryPopUp">
                                Add New Entry
                            </button>
                        </div>
                        <div className='col-6 text-end'>
                            <button type="button" className="btn btn-primary" onClick={() => setRefreshPatientEntryCount(refreshPatientEntryCount + 1)}>
                                Refresh Token Status
                            </button>
                        </div>
                    </div>
                    <div className="modal modal-lg fade" id="newEntryPopUp" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="newEntryPopUpLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="newEntryPopUpLabel">
                                        {tokenNumber === 0 ? "Add New Entry" : "Token Number"}
                                    </h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={resetPatientSearchResults}></button>
                                </div>
                                <div className="modal-body">
                                    <div id='patient-entry-form' style={{ width: '100%' }}>
                                        {
                                            Object.keys(nextPatient).length === 0 ?
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <label className='form-label'>Select Patient</label>
                                                        <form className='form row' onSubmit={event => searchPatient(event)}>
                                                            <div className='col-5'>
                                                                <input type='text' placeholder='Phone Number' className='form-control' value={phoneNumberToSearch} onChange={event => setPhoneNumberToSearch(event.target.value)} required />
                                                            </div>
                                                            <div className='col-7'>
                                                                <button type='submit' className='btn btn-outline-success'>Search</button>
                                                                <button type='button' className='btn btn-outline-success ms-3' data-bs-target="#addNewPatientPopUp" data-bs-toggle="modal">Add New Patient</button>
                                                            </div>
                                                        </form>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <table className='table table-primary table-hover table-striped table-responsive mt-3'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th className='text-center'>#</th>
                                                                            <th>Name</th>
                                                                            <th>DOB</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody style={{ verticalAlign: 'middle' }}>
                                                                        {
                                                                            searchingPatientDetails ?
                                                                                <tr>
                                                                                    <td className='text-center' colSpan={4}>
                                                                                        <div className="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                                                            <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: '100%' }}>Searching. Please Wait...</div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                :
                                                                                patientSearchResults.length > 0 ?
                                                                                    patientSearchResults.map((patient, index) => {
                                                                                        return <tr>
                                                                                            <td className='text-end'>{index + 1}</td>
                                                                                            <td>{patient.fname + " " + patient.lname}</td>
                                                                                            <td>{patient.dob}</td>
                                                                                            <td>
                                                                                                <button className='btn btn-outline-success' onClick={() => setNextPatientDetail(patient)}>Select</button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    })
                                                                                    :
                                                                                    <tr>
                                                                                        <td className='text-center' colSpan={4}><em>No Data Found</em></td>
                                                                                    </tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                tokenNumber === 0 ?
                                                    <form className='form' onSubmit={event => generateToken(event)}>
                                                        <div className='row mb-3'>
                                                            <div className='col-7'>
                                                                <table>
                                                                    <tr>
                                                                        <th>Patient Name</th>
                                                                        <th> : </th>
                                                                        <td>{nextPatient.fname + " " + nextPatient.lname}</td>
                                                                        <td rowSpan={2} className='ps-5'>
                                                                            <button className='btn btn-outline-success' onClick={changeNextPatient}>Change Patient</button>
                                                                        </td>
                                                                    </tr><tr>
                                                                        <th>Patient DOB</th>
                                                                        <th> : </th>
                                                                        <td>{nextPatient.dob}</td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                            <div className='col-5'>
                                                                <span className='text-danger fs-4'>{newEntryErrorMsg}</span>
                                                            </div>
                                                        </div>
                                                        <div className='row mb-3'>
                                                            <div className='col-6'>
                                                                <label className='form-label'><span className='text-danger'>* </span>Weight</label>
                                                                <input type='number' className='form-control text-start' value={patientWeight} onChange={event => setNewEntryInput('patientWeight', event.target.value, true)} />
                                                            </div>
                                                            <div className='col-6'>
                                                                <label className='form-label'><span className='text-danger'>* </span>Age</label>
                                                                <input type='text' className='form-control' value={patientAge} onChange={event => setNewEntryInput('patientAge', event.target.value, false)} disabled={nextPatient.dob !== null} />
                                                            </div>
                                                        </div>
                                                        <div className='row mb-3'>
                                                            <div className='col-6'>
                                                                <label className='form-label'>Temperature</label>
                                                                <input type='text' className='form-control' value={patientTemperature} onChange={event => setNewEntryInput('patientTemperature', event.target.value, false)} />
                                                            </div>
                                                            <div className='col-6'>
                                                                <label className='form-label'>Blood Pressure</label>
                                                                <input type='text' className='form-control' value={patientBP} onChange={event => setNewEntryInput('patientBP', event.target.value, false)} />
                                                            </div>
                                                        </div>
                                                        <div className='row mb-3'>
                                                            <div className='col-6'>
                                                                <label className='form-label'>Sugar</label>
                                                                <input type='text' className='form-control' value={patientSugar} onChange={event => setNewEntryInput('patientSugar', event.target.value, false)} />
                                                            </div>
                                                        </div>
                                                        <div className='row mb-3'>
                                                            <div className='col-12'>
                                                                <label className='form-label'><span className='text-danger'>* </span>Symptoms <small><em>({patientSymptoms.length} / 200)</em></small></label>
                                                                <textarea className='form-control' value={patientSymptoms} onChange={event => setNewEntryInput('patientSymptoms', event.target.value, false)} />
                                                            </div>
                                                        </div>
                                                        <div className='row mb-3'>
                                                            <div className='col-12'>
                                                                <label className='form-label'>Brief about the problem <small><em>({patientProbBrief.length} / 200)</em></small></label>
                                                                <textarea className='form-control' value={patientProbBrief} onChange={event => setNewEntryInput('patientProbBrief', event.target.value, false)} />
                                                            </div>
                                                        </div>
                                                        <div className='row mb-3'>
                                                            <div className='col-6'>
                                                                <label className='form-label'>Attendant Name</label>
                                                                <input type='text' className='form-control' value={patientAttendantName} onChange={event => setNewEntryInput('patientAttendantName', event.target.value, false)} />
                                                            </div>
                                                            <div className='col-6'>
                                                                <label className='form-label'>Attendant Phone Number</label>
                                                                <input type='number' className='form-control text-start' value={patientAttendantPhNumber} onChange={event => setNewEntryInput('patientAttendantPhNumber', event.target.value, true)} />
                                                            </div>
                                                        </div>
                                                        <div className='row pt-3'>
                                                            <div className='col-12'>
                                                                <button type='submit' className='btn btn-primary fullsizedbutton' disabled={isGeneratingToken}>
                                                                    {isGeneratingToken ?
                                                                        <div class="spinner-border text-light" role="status" style={{ width: '1.5rem', height: '1.5rem' }}>
                                                                            <span class="visually-hidden">Loading...</span>
                                                                        </div>
                                                                        :
                                                                        "Generate Token"
                                                                    }
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    :
                                                    <div className='text-center'>
                                                        <div id="token-display">
                                                            {tokenNumber}
                                                        </div>
                                                        <button className='btn btn-outline-primary' onClick={resetTokenNumber}>
                                                            Add Another Entry
                                                        </button>
                                                    </div>
                                        }

                                    </div>
                                </div>
                                {/*<div className="modal-footer">
                                    <button className="btn btn-primary" data-bs-target="#tokenDisplayPopUp" data-bs-toggle="modal">Token Display</button>
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-primary">Add Entry</button>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="tokenDisplayPopUp" aria-hidden="true" data-bs-backdrop="static" aria-labelledby="tokenDisplayPopUpLabel" tabIndex="-1">
                        <div className="modal-dialog modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="tokenDisplayPopUpLabel">Token Number</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='text-center'>
                                        {isGeneratingToken ?
                                            <div className="text-center spinner-border text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            :
                                            <div id="token-display">
                                                {tokenNumber}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-primary" data-bs-target="#newEntryPopUp" data-bs-toggle="modal" onClick={resetPatientSearchResults}>Add New Entry</button>
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="addNewPatientPopUp" aria-hidden="true" data-bs-backdrop="static" aria-labelledby="addNewPatientPopUpLabel" tabIndex="-1">
                        <div className="modal-dialog modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="addNewPatientPopUpLabel">Add New Patient</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-12 text-center'>
                                            {
                                                addNewPatientErrorMsg === "" ?
                                                    <span>&nbsp;</span>
                                                    :
                                                    <span className='text-danger'>{addNewPatientErrorMsg}</span>
                                            }
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label className='form-label'><span className='text-danger'>* </span>First Name <span className='char-count'>{newPatientFName.length + "/" + fieldMaxLen['newPatientFName']}</span></label>
                                            <input type="text" placeholder='First Name' className='form-control' value={newPatientFName} onChange={event => setNewPatientInput('newPatientFName', event.target.value, true)} />
                                        </div>
                                        <div className='col-6'>
                                            <label className='form-label'><span className='text-danger'>* </span>Last Name <span className='char-count'>{newPatientLName.length + "/" + fieldMaxLen['newPatientLName']}</span></label>
                                            <input type="text" placeholder='Last Name' className='form-control' value={newPatientLName} onChange={event => setNewPatientInput('newPatientLName', event.target.value, true)} />
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <label className='form-label'><span className='text-danger'>* </span>Phone Number <span className='char-count'>{newPatientPhone.length + "/" + fieldMaxLen['newPatientPhone']}</span></label>
                                            <input type="number" placeholder='Phone Number' className='form-control' style={{ textAlign: 'left' }} value={newPatientPhone} onChange={event => setNewPatientInput('newPatientPhone', event.target.value, true)} />
                                        </div>
                                        <div className='col-6'>
                                            <label className='form-label'>Date of Birth</label>
                                            <input type="date" placeholder='Last Name' className='form-control' value={newPatientDOB} onChange={event => setNewPatientInput('newPatientDOB', event.target.value, true)} />
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <label className='form-label'>Blood Group <span className='char-count'>{newPatientBloodGroup.length + "/" + fieldMaxLen['newPatientBloodGroup']}</span></label>
                                            <input type="text" placeholder='Blood Group' className='form-control' value={newPatientBloodGroup} onChange={event => setNewPatientInput('newPatientBloodGroup', event.target.value, false)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-primary" onClick={addNewPatient}>Add Patient</button>
                                    <button type="button" className="btn btn-secondary" data-bs-target="#newEntryPopUp" data-bs-toggle="modal" onClick={resetAddNewPatientForm}>Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-3'>
                            Total Records : {patientEntryTotalRecords}
                        </div>
                        <div className='col-6 text-center'>
                            <ul class="pagination justify-content-center">
                                <li class={"page-item" + (patientEntryCurrentPage === 1 ? " disabled" : "")}>
                                    <span class="page-link" aria-label="Next" onClick={() => setPatientEntryCurrentPage(1)} style={{cursor: 'pointer'}}>
                                        <span aria-hidden="true">&#8676;</span>
                                    </span>
                                </li>
                                <li class={"page-item" + (patientEntryCurrentPage === 1 ? " disabled" : "")}>
                                    <span class="page-link" aria-label="Previous" onClick={() => setPatientEntryCurrentPage(patientEntryCurrentPage - 1)} style={{cursor: 'pointer'}}>
                                        <span aria-hidden="true">&laquo;</span>
                                    </span>
                                </li>
                                {
                                    patientEntryPageNosToShow.map((pageNumber, index) => {
                                        return <li class={"page-item" + (patientEntryCurrentPage === pageNumber ? " active" : "")}><span class="page-link" onClick={() => setPatientEntryCurrentPage(pageNumber)} style={{cursor: 'pointer'}}>{pageNumber}</span></li>
                                    })
                                }
                                <li class={"page-item" + (patientEntryCurrentPage === patientEntryTotalPages ? " disabled" : "")}>
                                    <span class="page-link" aria-label="Next" onClick={() => setPatientEntryCurrentPage(patientEntryCurrentPage + 1)} style={{cursor: 'pointer'}}>
                                        <span aria-hidden="true">&raquo;</span>
                                    </span>
                                </li>
                                <li class={"page-item" + (patientEntryCurrentPage === patientEntryTotalPages ? " disabled" : "")}>
                                    <span class="page-link" aria-label="Next" onClick={() => setPatientEntryCurrentPage(patientEntryTotalPages)} style={{cursor: 'pointer'}}>
                                        <span aria-hidden="true">&#8677;</span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className='col-3 text-end'>
                            <table className='text-end' style={{ width: '100%' }}>
                                <tr>
                                    <td>Records/Page</td>
                                    <td>:</td>
                                    <td>
                                        <select className='form-select' value={patientEntryRecPerPage} onChange={event => setPatientEntryRecPerPage(event.target.value)}>
                                        <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={30}>30</option>
                                        </select>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <ul className="nav nav-tabs">
                        <li className="nav-item text-primary">
                            <button className={"nav-link" + (activeTab === "waiting" ? " active" : "")} onClick={() => setActiveTab("waiting")}>Waiting</button>
                        </li>
                        <li className="nav-item">
                            <button className={"nav-link" + (activeTab === "inprogress" ? " active" : "")} onClick={() => setActiveTab("inprogress")}>In-Progress</button>
                        </li>
                        <li className="nav-item">
                            <button className={"nav-link" + (activeTab === "booked" ? " active" : "")} onClick={() => setActiveTab("booked")}>Booked</button>
                        </li>
                        <li className="nav-item">
                            <button className={"nav-link" + (activeTab === "completed" ? " active" : "")} onClick={() => setActiveTab("closed")}>Closed</button>
                        </li>
                    </ul>
                    <div>
                        <table className='table table-striped table-primary table-responsive' style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th className='text-end'>&nbsp;</th>
                                    <th className='text-end'>#</th>
                                    <th className='text-end'>Token</th>
                                    <th className='text-center'>Date</th>
                                    <th className='text-center'>Type</th>
                                    <th className='text-center'>Patient Name</th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Temperature</th>
                                    <th className='text-center'>Blood Pressure</th>
                                    <th className='text-center'>Sugar Level</th>
                                    <th className='text-center'>Weight</th>
                                    <th className='text-center'>Age</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    patientEntryListLoading ?
                                        <tr>
                                            <td className='text-center' colSpan={12}>
                                                <div className="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                    <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: '100%' }}>Loading. Please Wait...</div>
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        waitingPatients.length === 0 ?
                                            <tr>
                                                <td className='text-center' colSpan={12}><em>No Data Found</em></td>
                                            </tr>
                                            :
                                            waitingPatients.map((patientEntry, index) => {
                                                return <tr>
                                                    <td><FiEdit3 style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                    <td className='text-end text-nowrap'>{((patientEntryCurrentPage - 1) * patientEntryRecPerPage) + index + 1}.</td>
                                                    <td className='text-end text-nowrap' style={{ color: 'blue', cursor: 'pointer' }}>{patientEntry.token_number}</td>
                                                    <td className='text-center text-nowrap'>{patientEntry.date}</td>
                                                    <td className={'text-center text-nowrap' + (patientEntry.type === 'Online' ? ' text-danger' : '')}>{patientEntry.type}</td>
                                                    <td className='text-center text-nowrap'>{patientEntry.fname + " " + patientEntry.lname}</td>
                                                    <td className='text-center text-nowrap'>{patientEntry.status}</td>
                                                    <td className='text-center text-nowrap'>{patientEntry.temperature}</td>
                                                    <td className='text-center text-nowrap'>{patientEntry.bp}</td>
                                                    <td className='text-center text-nowrap'>{patientEntry.sugar}</td>
                                                    <td className='text-center text-nowrap'>{patientEntry.weight}</td>
                                                    <td className='text-center text-nowrap'>{patientEntry.age}</td>
                                                </tr>
                                            })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div >
    )
}

import React from "react";

function HomePage() {
    return(
        <div id="homepage-component">
            <h1>HomePage</h1>
        </div>
    )
}

export default HomePage;
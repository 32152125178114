import React, { useState } from 'react';
import './css/treatment-details.css';

export default function TreatmentDetails() {
    const [searchByToken, setSearchByToken] = useState(false);

    const fetchDetails = (event) => {
        event.preventDefault();
    }

    return (
        <div className='view-area mt-3'>
            <div className='row'>
                <div className='col-6'>
                    <div className='row'>
                        <div className='col-2 offset-2'>
                            <label className='form-label'>Room</label>
                        </div>
                        <div className='col-4 ps-3'>
                            <input type='checkbox' name='searchbytoken' id='searchbytoken' value={searchByToken} onChange={() => setSearchByToken(!searchByToken)} />
                            <label htmlFor='searchbytoken' className='ps-1'>Fetch By Token</label>
                        </div>
                    </div>
                    <form className='row' onSubmit={event => fetchDetails(event)}>
                        <div className='col-2 offset-2'>
                            <select className='form-select'>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                            </select>
                        </div>
                        {
                            searchByToken ?
                                <div className='col-8'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <input type='number' className='form-control' placeholder='Token Number' required disabled={!searchByToken} />
                                        </div>
                                        <div className='col-5'>
                                            <button type='submit' className='btn btn-outline-success fullsizedbutton' disabled={!searchByToken}>Fetch Details</button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='col-4'>
                                    <button type='button' className='btn btn-outline-success fullsizedbutton' disabled={searchByToken}>Next Token</button>
                                </div>
                        }
                    </form>
                </div>
                <div className='col-6'>
                    <div className='row mt-1'>
                        <div className='col-12 text-center'>
                            <h6><u><b>Patient Details</b></u></h6>
                        </div>
                    </div>
                    <small><div className='row'>
                        <div className='col-5 offset-1'>
                            <table className='table mx-auto' style={{ width: 'max-content' }}>
                                <tr>
                                    <th>Patient Name</th>
                                    <th>&nbsp;:&nbsp;</th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>Patient Phone Number</th>
                                    <th>&nbsp;:&nbsp;</th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>Patient DOB</th>
                                    <th>&nbsp;:&nbsp;</th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>Attendant Name</th>
                                    <th>&nbsp;:&nbsp; </th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>Attendant Phone Number</th>
                                    <th>&nbsp;:&nbsp;</th>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                        <div className='col-5'>
                            <table className='table mx-auto' style={{ width: 'max-content' }}>
                                <tr>
                                    <th>Blood Sugar</th>
                                    <th>&nbsp;:&nbsp;</th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>Blood Pressure</th>
                                    <th>&nbsp;:&nbsp;</th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>Weight</th>
                                    <th>&nbsp;:&nbsp;</th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>Symptoms</th>
                                    <th>&nbsp;:&nbsp; </th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>Problem Brief</th>
                                    <th>&nbsp;:&nbsp;</th>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                    </div></small>
                </div>
            </div>

            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <h3>Token Number : </h3>
                </div>
            </div>

        </div>
    )
}
